<template>
    <div class="page-resources">
        page-resources
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
// .page-resources {}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {}
</style>